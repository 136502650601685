import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from 'src/stores/auth-context';

import useCommerceConf from 'src/hooks/useCommerceConf';

import DeliveryRouteBoard from 'src/atomic-components/templates/DeliveryRouteBoard/DeliveryRouteBoard';
import DeliveryPerformanceDashboard from 'src/components/Delivery/DeliveryPerformance/DeliveryPerformanceDashboard';
import DeliverySpotWithStore from 'src/components/Delivery/DeliverySpotWithStore/DeliverySpotWithShop';
import PartnersUserTable from 'src/components/Delivery/PartnersUserTable/PartnersUserTable';
import TaskHistoryPage from 'src/components/Delivery/TaskHistoryPage/TaskHistoryPage';
import DeliverySpotMap from 'src/components/DeliverySpot/DeliverySpotMap/DeliverySpotMap';
import PackingInfoSheetDashboard from 'src/components/PackingInfoSheetDashboard/PackingInfoSheetDashboard';
import PackingSheetDashboard from 'src/components/PackingSheetDashboard/PackingSheetDashboard';
import PaperInvoiceDashboard from 'src/components/PaperInvoice/PaperInvoiceDashboard';

const DeliveryPage: FC = () => {
  const { user } = useAuth();
  const { getDeliveryDateForNow } = useCommerceConf();
  const date = getDeliveryDateForNow();
  return (
    <Routes>
      {date && <Route path='/route' element={<DeliveryRouteBoard date={date} />} />}
      <Route path='/partners-user' element={<PartnersUserTable />} />
      <Route path='/map' element={<DeliverySpotMap />} />
      <Route path='/spot' element={<DeliverySpotWithStore />} />
      <Route path='/packing-sheet' element={<PackingSheetDashboard />} />
      <Route path='/invoice' element={<PaperInvoiceDashboard />} />
      <Route path='/print' element={<PackingInfoSheetDashboard />} />
      <Route path='/task-history' element={<TaskHistoryPage />} />
      {user?.role === 'admin' && (
        <Route
          path='/performance'
          element={
            <div className='height100 flexColumn'>
              <Outlet />
            </div>
          }>
          <Route path='table' element={<DeliveryPerformanceDashboard tab='table' />} />
          <Route path='chart-order-amount' element={<DeliveryPerformanceDashboard tab='chart-order-amount' />} />
          <Route path='chart-spot' element={<DeliveryPerformanceDashboard tab='chart-spot' />} />
          <Route path='chart-store' element={<DeliveryPerformanceDashboard tab='chart-store' />} />
          <Route path='*' element={<Navigate to='table' replace />} />
        </Route>
      )}
      <Route path='*' element={<Navigate to={`${user?.role === 'admin' ? './route' : './spot'}`} replace />} />
    </Routes>
  );
};

export default DeliveryPage;
